import React from 'react';
import { Field, Fields } from 'redux-form/immutable';
import Grid from '@material-ui/core/Grid';
import RenderTextField from 'components/Evento/TextField';
import RenderDateField from 'components/Evento/DateField';
import RenderCheckField from 'components/Evento/CheckField';
import { formatFromUnix } from 'helpers/utils';
const styles ={
textGray:{
 color: '#0000008a',
 fontFamily:'Roboto',
 fontWeight:300
}
}
const RenderName = (props) => {
  const {
    station, id, name, slug, publicAt, classes,
  } = props;
  // const url = id.input.value && `${station.input.value.get('baseUrl')}${id.input.value}/${publicAt.input.value ? slug.input.value : slugify(name.input.value)}`;
  return (
    <Field
      label="Nombre del Evento"
      name="name"
      margin="normal"
      fullWidth
      component={RenderTextField}
    />
  );
};
export default class EditConfig extends React.PureComponent  {


  render(){
    const {
      handleShowMessage, params, classes, thankText,tmpFiles, nameForm, stateApp, stations, /* stateUser, author, */
    } = this.props;
    return ( 
    <>
    <Grid item xs={12} className={classes.gridItemField}>
      <Fields
        label="Nombre del Evento"
        names={['name']}
        margin="normal"
        classes={classes}
        component={RenderName}
      />
    </Grid>
    <Grid item xs={6} className={classes.gridItemField}>
      <Field
        label="Fecha de Inicio"
        name="startDAte"
        margin="normal"
        placeholder="dd/MM/yyyy HH:mm:ss"
        fullWidth
        component={RenderDateField}
        helperText={input => (input.value ? `Inicio ${formatFromUnix(input.value, 'dd/MM/yyyy HH:mm:ss')}`: '')}
      />
    </Grid>
    <Grid item xs={6} className={classes.gridItemField}>
    <Field
      label="Fecha de Fin"
      name="endDate"
      margin="normal"
      placeholder="dd/MM/yyyy HH:mm:ss"
      fullWidth
      component={RenderDateField}
      helperText={input => (input.value ? `Fin ${formatFromUnix(input.value, 'dd/MM/yyyy HH:mm:ss')}`: '')}
    />
  </Grid>
    <Grid item xs={6} className={classes.gridItemField}>
    <Field
      label="Activo"
      name="active"
      component={RenderCheckField}
    />
  </Grid>
  </>
);
}
}

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ButtonBase from '@material-ui/core/ButtonBase';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import withStyles from '@material-ui/core/styles/withStyles';
import { ROLE_ADMIN } from 'helpers/constants';
import store from 'helpers/localStore';
import config from 'config.json';
import styles from './HeaderStyles';

class Header extends PureComponent {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  state = {
    anchorEl: null,
  }

  handleClickOpenMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleLogout = () => {
    store.delete('token');
    document.location.href = '/login';
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, user } = this.props;
    const { anchorEl } = this.state;
    const roleId = user.getIn(['role', 'value']);
    return (
      <Grid
        item
        container
        alignItems="center"
        wrap="nowrap"
        justify="space-between"
        spacing={0}
        className={classes.root}
      >
        <Link to="/forms" className={classes.brand}>
          <img src={config.logoRPP} alt={config.appName} title={config.appName} />
          {/* <sup>{`V.${config.version}`}</sup> */}
        </Link>

        <div style={{ marginLeft: 'auto' }} />
        <div className={classes.links}>
          <ButtonBase to="/forms" component={Link}>Formularios</ButtonBase>
          <ButtonBase to="/landings" component={Link}>Landings Leads</ButtonBase>
          <ButtonBase to="/eventos" component={Link}>Eventos</ButtonBase>
          {roleId === ROLE_ADMIN ? (<ButtonBase to="/users" component={Link}>Usuarios</ButtonBase>) : null}
          {roleId === ROLE_ADMIN ? (<ButtonBase to="/log" component={Link}>Log</ButtonBase>) : null}
        </div>
        <Avatar
          onClick={this.handleClickOpenMenu}
          title={`${user.get('names')} ${user.get('surnames')}`}
          className={classes.avatar}
        >
          {user.get('names').substring(0, 1)}
        </Avatar>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          elevation={10}
          onClose={this.handleClose}
        >
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="space-between"
            spacing={0}
            className={classes.username}
          >
            <Avatar
              title={`${user.get('names')} ${user.get('surnames')}`}
              className={classes.avatarInner}
            >
              {user.get('names').substring(0, 1)}
            </Avatar>

            <Grid item className={classes.name}>
              <Typography component="div" variant="body2">
                {`${user.get('names')} ${user.get('surnames')}` }
              </Typography>
              <Typography component="div">
                {user.get('email')}
              </Typography>
            </Grid>
          </Grid>
          <Divider light />
          <MenuItem onClick={this.handleLogout} data-type="close">Cerrar sesión</MenuItem>
        </Menu>
      </Grid>
    );
  }
}

export default withStyles(styles, { name: 'AppHeader' })(Header);

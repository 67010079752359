import React from 'react';
import { Link } from 'react-router-dom';
import {
  Fields, FieldArray, destroy, initialize,
} from 'redux-form/immutable';
import Grid from '@material-ui/core/Grid';
import Badge from '@material-ui/core/Badge';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import clsx from 'clsx';
import { SUCCESS, REQUEST } from 'helpers/constants';
import {  validate } from 'helpers/utils';
import binder from 'helpers/binder';
import { getEventos, saveEventos } from 'reducers/eventos';
import Confirm from 'components/Confirm';
import BaseContainer from 'components/BaseContainer';

import styles from './EditStyles';
import EditConfig from './EditConfig';
import withDragDropContext from './DragDropContext';



const FieldArrayDnd = withDragDropContext(FieldArray);

const nameForm = 'eventosEdit';


class EventosEdit extends BaseContainer {

  wrapCardsRef = React.createRef();
  wrapCardsRef2 = React.createRef();
  formDown = React.createRef();

  state = {
    tabSelecIndex: 0,
    anchorEl: null,
    typeFile: 'xls',
    formatTop: false, // false estandar true formato top
    csvLoading: false
  }

  componentWillMount = () => {
    const { actions, match: { params } } = this.props;
    actions.getEventos(params.eventoId);
    // const isNew = params.eventoId === 'new';
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      stateSecond, dispatch, match, history,
    } = this.props;
    const { stateSecond: stateSecondNew } = nextProps;
  
    const statusSecond = (stateSecond && stateSecond.get('status')) || false;
    const statusSecondNew = (stateSecondNew && stateSecondNew.get('status')) || false;
    if (statusSecond === REQUEST && statusSecondNew === SUCCESS) {
      if (match.params.eventoId === 'new') {
        history.replace(`${match.url.replace('new', stateSecondNew.getIn(['data', 'id']))}`);
      }
      dispatch(initialize(nameForm, stateSecondNew.get('data')));
    }
  }

  componentWillUnmount = () => {
    const { dispatch, actions } = this.props;
    dispatch(destroy(nameForm));
    actions.getEventos({ initialize: true });
  }


  handleShowMessage = params => (
    Confirm(params.message, {
      title: params.title,
      hideCancel: true,
    })
  );

  handleChangeTab = (event, newValue) => this.setState({ tabSelecIndex: newValue })

  handleSubmit = (values) => {
    const { actions } = this.props;
    // cojemos archivos de variable global
    actions.saveEventos({
      data: values.toJS()
    });
  }

  handleDownload = (event) => {
    event.stopPropagation();

    this.setState({ anchorEl: event.currentTarget });
    // const { current } = this.formDown;
    // current.submit();
  }




  setPickerStatus = (event) => {
    if (event) { event.stopPropagation(); }
    this.setState({ anchorEl: null });
  }

  handleDateChange = (date, idForm) => {
    if (date) {
      date = date.setHours(0, 0, 0, 0);
      this.handleExportCsv(null, idForm, date/1000);
    }
  };

  toRenderTitlePage = () => 'Edición de formulario';

  toRender() {
    const {
      classes, stateApp, dirty, handleSubmit, submitFailed,
      formErrors, stateUser, match: { params },
    } = this.props; // eslint-disable-line
    const { tabSelecIndex } = this.state;
    const tab0Error = formErrors && (
      formErrors.name || formErrors.url || formErrors.station
      || formErrors.restriction || formErrors.title
    );
    const isNew = params.eventoId === 'new';
    return (
      <React.Fragment>
        <Grid container alignItems="center" wrap="nowrap" justify="space-between"spacing={0} className={classes.hdRoot}>
          <Grid item className={classes.hdRootCol1}>
            <IconButton
              aria-label="Return"
              className={classes.backIcon}
              component={Link}
              to="/eventos"
              edge="start"
              title="Volver a lista de formularios"
            >
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item className={classes.hdRootCol2}>
            <Tabs
              value={tabSelecIndex}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleChangeTab}
            >
              <Tab label={
                (
                  <Badge
                    className={classes.margin}
                    color="secondary"
                    variant="dot"
                    invisible={!(submitFailed && tab0Error)}
                  >
                    configuración Evento
                  </Badge>
                )}
              />
            </Tabs>
          </Grid>
          <Grid item >
            <Button
              variant="contained"
              color="primary"
              disabled={!dirty}
              onClick={() => handleSubmit(this.handleSubmit)()}
            >      
              <SaveIcon className={classes.hdLeftIcon} />
              Guardar
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignContent="flex-start"
          className={classes.bdWrap}
          id="bodyForm"
        >
          <Grid
            container
            className={clsx(classes.bdInner, { [classes.hidden]: tabSelecIndex !== 0 })}
            spacing={2}
          >
            <Fields
              names={[
                'name',
                'startDAte',
                'endDate',
                "Active"
              ]}
              stateUser={stateUser}
              stateApp={stateApp}
              classes={classes}
              params ={params}
              component={EditConfig}
              handleShowMessage={this.handleShowMessage}
             
              nameForm={nameForm}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default binder({
  styles,
  stylesName: nameForm,
  reduxForm: {
    destroyOnUnmount: false,
    form: nameForm,
    validate: (values) => {
      let errors = {};
      if (typeof values === 'string') return errors;
      errors = validate.isEmpty('name,startDAte,endDate', values, errors);
      const fields = values.get('fields');
      if (!fields || fields.size === 0) {
        errors.fields = 'error';
      }
      if (values.get('endDate') < values.get('startDAte')) {
        errors.endDate = 'error';
        errors.startDAte = 'error';
        errors.fields = 'error';
      }

      return errors;
    },
  },
  states: {
    stateMain: ['eventos', 'get'],
    stateSecond: ['eventos', 'save'],
    stateApp: ['app', 'resources', 'data'],
    initialValues: ['eventos', 'get', 'data'],
    formErrors: ['form', nameForm, 'syncErrors'],
    stateUser: ['user', 'login', 'data'],
  },
  actions: { getEventos, saveEventos },
  component: EventosEdit,
});

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CustomizedMenus from 'components/dropdownButton'
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
// import PropTypes from 'prop-types';
import binder from 'helpers/binder';

const styles = theme => ({
  root: {
    // boxShadow: `inset 0 -1px 0 ${theme.app.colorLine}`,
    padding: `${theme.spacing(5)}px 0 ${theme.spacing(2)}px 0`,
  },
  title: {
    fontWeight: 100,
    whiteSpace: 'nowrap',
  },
  button: {
    padding: '8px 16px',
    marginLeft: theme.spacing(1),
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
});

class ListHeader extends React.PureComponent {
  handleReturn = () => {
    const { history, match } = this.props;
    history.push(match.url.split(/nuevo|editar/)[0]);
  }

  render() {
    const {
      classes, title, filterContent, handleNew, handleNewTop
    } = this.props;
    return (
      <Grid
        container
        wrap="nowrap"
        direction="row"
        justify="space-between"
        alignItems="stretch"
        className={classes.root}
      >
        {title && (
          <Grid item>
            <Typography variant="h4" gutterBottom className={classes.title}>
              {title}
            </Typography>
          </Grid>
        )}
        <Grid
          container
          wrap="nowrap"
          direction="row"
          justify="flex-end"
          alignItems="stretch"
        >
          {filterContent && (
            <Grid item xs={5}>
              {filterContent}
            </Grid>
          )}
          {title==='Formularios'?(
          <Grid item>
            <CustomizedMenus 
            classes={classes}
            handleNew={handleNew}
            handleNewTop={handleNewTop}
             variant="contained"/>
            </Grid>):(title==='Usuarios'?(
          <Grid item>
          <Button variant="contained" color="primary" className={classes.button} onClick={handleNew}>
                <AddIcon />
                Nuevo
              </Button>
            </Grid>):(title==='Eventos'?(   <Grid item>
              <Button variant="contained" color="primary" className={classes.button} onClick={handleNew}>
                    <AddIcon />
                    Nuevo
                  </Button>
                </Grid>):""))}
        </Grid>
      </Grid>
    );
  }
}

// ListHeader.defaultProps = {
//   filterContent: null,
//   title: '',
// };

// ListHeader.propTypes = {
//   title: PropTypes.string,
//   handleNew: PropTypes.func.isRequired,
//   filterContent: PropTypes.element,
// };


export default binder({
  styles,
  stylesName: 'ListSearchHeader',
  reduxForm: {
    form: 'listSearchForm',
  },
  component: ListHeader,
});

// export default withStyles(styles, { name: 'ListSearchHeader' })(ListHeader);

import { fromJS } from 'immutable';
import { createAction, handleActions } from 'redux-actions';
import {put, takeLatest, call,} from 'redux-saga/effects';
import { REQUEST, FAILURE, SUCCESS } from 'helpers/constants';

import request from 'helpers/request';
import { types as typesNotification } from 'reducers/notification';

// Constants
export const types = {
  LIST_REQUEST: 'EVENTOS/LIST_REQUEST',
  LIST_SUCCESS: 'EVENTOS/LIST_SUCCESS',
  LIST_FAILURE: 'EVENTOS/LIST_FAILURE',

  GET_REQUEST: 'EVENTOS/GET_REQUEST',
  GET_SUCCESS: 'EVENTOS/GET_SUCCESS',
  GET_FAILURE: 'EVENTOS/GET_FAILURE',
  GET_INITIAL: 'EVENTOS/GET_INITIAL',

  SAVE_REQUEST: 'EVENTOS/SAVE_REQUEST',
  SAVE_SUCCESS: 'EVENTOS/SAVE_SUCCESS',
  SAVE_FAILURE: 'EVENTOS/SAVE_FAILURE',

  DELETE_REQUEST: 'EVENTOS/DELETE_REQUEST',
  DELETE_SUCCESS: 'EVENTOS/DELETE_SUCCESS',
  DELETE_FAILURE: 'EVENTOS/DELETE_FAILURE',
};

// Actions
export const listEventos = createAction(types.LIST_REQUEST);
export const getEventos = createAction(types.GET_REQUEST);
export const saveEventos = createAction(types.SAVE_REQUEST);
export const deleteEventos = createAction(types.DELETE_REQUEST);

const initialState = fromJS({
  list: {
    status: null,
    data: null,
  },
  get: {
    status: null,
    data: null,
  },
  save: {
    status: null,
    data: null,
  },
  delete: {
    status: null,
    data: null,
  },
});

// Reducer
export default handleActions(
  {
    [types.LIST_SUCCESS]: (state, action) => {
      const newState = state.setIn(['list', 'status'], SUCCESS);
      return newState.setIn(['list', 'data'], fromJS(action.data));
    },
    [types.LIST_REQUEST]: state => state.setIn(['list', 'status'], REQUEST),
    [types.LIST_FAILURE]: (state, action) => {
      const newState = state.setIn(['list', 'status'], FAILURE);
      return newState.setIn(['list', 'data'], fromJS(action.data));
    },

    [types.GET_SUCCESS]: (state, action) => {
      const newState = state.setIn(['get', 'status'], SUCCESS);
      return newState.setIn(['get', 'data'], fromJS(action.data));
    },
    [types.GET_REQUEST]: state => state.setIn(['get', 'status'], REQUEST),
    [types.GET_FAILURE]: (state, action) => {
      const newState = state.setIn(['get', 'status'], FAILURE);
      return newState.setIn(['get', 'data'], fromJS(action.data));
    },
    [types.GET_INITIAL]: (state) => {
      const newState = state.setIn(['get', 'status'], null);
      return newState.setIn(['get', 'data'], fromJS(null));
    },

    [types.SAVE_SUCCESS]: (state, action) => {
      const newState = state.setIn(['save', 'status'], SUCCESS);
      return newState.setIn(['save', 'data'], fromJS(action.data));
    },
    [types.SAVE_REQUEST]: state => state.setIn(['save', 'status'], REQUEST),
    [types.SAVE_FAILURE]: (state, action) => {
      const newState = state.setIn(['save', 'status'], FAILURE);
      return newState.setIn(['save', 'data'], fromJS(action.data));
    },

    [types.DELETE_SUCCESS]: state => state.setIn(['delete', 'status'], SUCCESS),
    [types.DELETE_REQUEST]: state => state.setIn(['delete', 'status'], REQUEST),
    [types.DELETE_FAILURE]: state => state.setIn(['delete', 'status'], FAILURE),
  },
  initialState,
);

// sagas
function* listEventosSaga({ payload }) {
  const response = yield call(request, {
    api: 'eventos/list-eventos',
    method: 'POST',
    body: payload,
  });

  if (response.success) {
    yield put({ type: types.LIST_SUCCESS, data: response.body });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.LIST_FAILURE, data: response.body });
  }
}
function* getEventosSaga({ payload }) {
  if (payload && payload.initialize) {
    yield put({ type: types.GET_INITIAL });
    return;
  }
  const response = yield call(request, {
    api: `eventos/get/${payload}`,
    method: 'POST',
  });

  if (response.success) {
    yield put({ type: types.GET_SUCCESS, data: response.body });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.GET_FAILURE, data: response.body });
  }
}

function* saveEventosSaga(params) {
  const { data } = params.payload;
  // establecemos expiracion a final de dia.
  if (data.startDAte) {
    const startDAte = new Date(data.startDAte * 1000);
    startDAte.setHours(0, 0, 0, 0);
    data.startDAte = startDAte / 1000;
  }
  if (data.endDate) {
    const endDate = new Date(data.endDate * 1000);
    endDate.setHours(23, 59, 59, 0);
    data.endDate = endDate / 1000;
  }

  let response = yield call(request, {
    api: 'eventos/save',
    method: 'POST',
    body: data,
  });
  if (response.success) {
    // enviamos archivos
    yield put({ type: typesNotification.ADD,notification: {message: 'Datos guardados',type: 'success'}});
    yield put({ type: types.SAVE_SUCCESS, data: response.body });
    yield put({ type: types.GET_SUCCESS, data: response.body });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.SAVE_FAILURE });
  }
}

// const EventoListState = state => state.getIn(['EVENTO']);

function* deleteEventosSaga({ payload }) {
  // const listState = yield select(EventoListState);

  const response = yield call(request, {
    api: `eventos/delete/${payload.id}`,
    method: 'POST',
  });

  if (response.success) {
    yield put({ type: types.DELETE_SUCCESS });
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: 'Se ha eliminado el formulario',
        type: 'success',
      },
    });
  } else {
    yield put({
      type: typesNotification.ADD,
      notification: {
        message: response.body,
        type: 'error',
      },
    });
    yield put({ type: types.DELETE_FAILURE });
  }
}

export const deleteEventoAsync = id => request({
  api: `eventos/delete/${id}`,
  method: 'POST',
});

export const listEventoAsync = props => request({
  api: 'eventos/list-eventos',
  method: 'POST',
  body: props,
});

export const eventosSagas = [
  takeLatest(types.LIST_REQUEST, listEventosSaga),
  takeLatest(types.GET_REQUEST, getEventosSaga),
  takeLatest(types.SAVE_REQUEST, saveEventosSaga),
  takeLatest(types.DELETE_REQUEST, deleteEventosSaga),
];

import React from 'react';
import { formatFromUnix } from 'helpers/utils';
import ListEvento from 'components/ListSearch/eventos';
import BaseContainer from 'components/BaseContainer';
import { listEventoAsync, deleteEventoAsync } from 'reducers/eventos';
import createListSearchForm from 'components/ListSearch/formForma';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Confirm from 'components/Confirm';
import { ROLE_ADMIN } from 'helpers/constants';
import binder from 'helpers/binder';

// import { connect } from 'react-redux';

const ListFilter = createListSearchForm({
  mainInput: {
    label: 'Buscar',
    name: 'filter',
    fullWidth: true,
    placeholder: 'Buscar formulario...',
    variant: 'outlined',
  },
  binder: {
    reduxForm: {
      form: 'formaListFilterForm',
      destroyOnUnmount: false,
    },
    states: {
      stateUser: ['user', 'login', 'data'],
      // stateForm: ['form', 'formaListFilterForm', 'values'],
    },
  },
});

class EventoList extends BaseContainer {
  ignoreStatus = true;

  handleFilter = (values) => {
    const tableList = document.getElementById('table-list');
    tableList.dispatchEvent(new Event('filtered'));
  }

  handleNewEvent = () => {
    const { history, match } = this.props;
    history.push(`${match.url}/new`);
  }

  handleEdit = (item) => {
    const { history, match } = this.props;
    history.push(`${match.url}/${item.id}`);
  }

  handleDelete = (event, row) => {
    const { dispatch } = this.props;
    event.stopPropagation();
    Confirm(`¿Esta seguro de desactivar el Evento "${row.original.name}"?`, { title: 'Confirmar' }).then(() => {
      deleteEventoAsync(row.original.id).then((result) => {
        let notification;
        if (result.success) {
          const tableList = document.getElementById('table-list');
          if (tableList) {
            tableList.dispatchEvent(new Event('refresh'));
          }
          notification = {
            message: 'Se ha desactivado el Evento',
            type: 'success',
          };
        } else {
          notification = {
            message: 'No se ha eliminado el Evento, vuelve a intentar en unos minutos',
            type: 'error',
          };
        }
        dispatch({
          type: 'APP/NOTIFICATIONS/ADD',
          notification,
        });
      });
    }, () => {});
  }

  toRenderTitlePage = () => 'Lista de Eventos';

  toRender() {
    const { stateUser , stateForm } = this.props;
    const roleId = stateUser.getIn(['role', 'value']);
    const columns = [
      {
        accessor: 'name',
        Header: 'Nombre',
      },
      {
        accessor: 'active',
        Header: 'Activo',
      },
      {
        accessor: 'startDAte',
        Header: 'Fecha Inicio',
      },
      {
        accessor: 'endDate',
        Header: 'Fecha Fin',
      },
      {
        accessor: 'createdAt',
        Header: 'Creado en',
      },
      {
        accessor: 'updatedAt',
        Header: 'ultima Actualización',
      }
    ];

    if (roleId === ROLE_ADMIN) {
      columns.push({
        accessor: 'actions',
        Header: '',
        Cell: row => (
          <IconButton
            aria-label="File"
            size="medium"
            color="secondary"
            className="btn-act"
            onClick={event => this.handleDelete(event, row)}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        ),
      });
    }
    return (
      <React.Fragment>
        <ListEvento
          title="Eventos"
          handleNew={this.handleNewEvent}
          handleEdit={this.handleEdit}
          handleListAsync={listEventoAsync}
          columns={columns}
          filtro = {stateForm}
          formatDataRow={row => ({
            id: row.id,
            name: row.name,
            active: row.active,
            startDAte: formatFromUnix(row.startDAte, 'dd/MM/yyyy HH:mm:ss'),
            endDate:formatFromUnix(row.endDate, 'dd/MM/yyyy HH:mm:ss'),
            createdAt: formatFromUnix(row.createdAt, 'dd/MM/yyyy HH:mm:ss'),
            updatedAt: formatFromUnix(row.updatedAt, 'dd/MM/yyyy HH:mm:ss')
          })}
          filterContent={<ListFilter handleParentSubmit={this.handleFilter} />}
        />
      </React.Fragment>
    );
  }
}

// export default connect()(FormList);


export default binder({
  states: {
    stateUser: ['user', 'login', 'data'],
    // stateForm: ['form', 'formaListFilterForm', 'values'],
  },
  // actions: { listForma },
  component: EventoList,
});
